/**
 * Copyright 2024 The Subscribe with Google Authors. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS-IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Duration} from '../model/auto-prompt-config';
import {InterventionType} from './intervention-type';

export interface ActionOrchestration {
  interventionFunnel: InterventionFunnel;
}

export interface InterventionFunnel {
  id: string;
  globalFrequencyCap: FrequencyCapConfig;
  interventions: Array<InterventionOrchestration>;
}

export interface InterventionOrchestration {
  configId: string;
  type: InterventionType;
  promptFrequencyCap: FrequencyCapConfig;
  closability: Closability;
  repeatability: {
    type: RepeatabilityType;
    count: number;
  };
}

export enum Closability {
  UNSPECIFIED = 'UNSPECIFIED',
  DISMISSIBLE = 'DISMISSIBLE',
  BLOCKING = 'BLOCKING',
}

export enum RepeatabilityType {
  UNSPECIFIED = 'UNSPECIFIED',
  FINITE = 'FINITE',
  INFINITE = 'INFINITE',
}

interface FrequencyCapConfig {
  duration?: Duration;
}
